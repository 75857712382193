import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Attachment, AttachmentDTO } from '../interfaces/attachment.interface';
import { Commitment } from '../interfaces/commitment.interface';
import {
  CardDTO,
  CardTemplate,
  LetterCardTemplate,
  LetterDTO,
  LetterTemplate,
} from '../interfaces/letter-card-template.interface';
import {
  LetterAttachment,
  LetterPreview,
  LetterPreviewResult,
  LetterProgress,
  LetterProgressCommitment,
  ProgressOptions,
  ProgressSubject,
} from '../interfaces/letter-progress.interface';
import {
  Testimonial,
  TestimonialDTO,
} from '../interfaces/testimonial.interface';
import { WPCampaign } from '../interfaces/WPCampaign.interface';
import { Country } from '../interfaces/wpCountry.interface';
import { CacheService } from './cache.service';
import { LanguageService } from './language/language.service';
import { ProxyService } from './proxy.service';
import { WordpressService } from './wordpress/wordpress.service';
@Injectable({
  providedIn: 'root',
})
export class WriteLetterService {
  public static nullState: ProgressSubject = {
    data: {
      type: 'card',
      recipients: [],
      isEng: undefined,
      template: null,
      attachments: {},
      groupId: undefined,
    },
    progressOptions: {},
  };

  // #region properties
  /**
   * Contains the latest updates, added by this service only
   * @private
   * @memberof WriteLetterService
   */
  private composeLetter$ = new BehaviorSubject<ProgressSubject>(undefined);

  /**
   * Provides the latest updates of a letter or card
   * for any component or service besides this
   * @memberof WriteLetterService
   */
  public readonly letterProgress$ = this.composeLetter$
    .asObservable() // prevent leaving "observer side" of the BehaviorSubject
    .pipe(filter((progress) => !!progress)); // making sure that it emits only valued items.

  /**
   * Hold info about what IS & ISNOT allowed to
   * send to a child in a given country. Ie: bikini | birkini
   * @type {Country[]}
   * @memberof WriteLetterService
   */
  private countryInfo = new BehaviorSubject<Country[]>(undefined);

  /**
   * Stores country info
   *
   * @memberof WriteLetterService
   */
  public readonly getCountryInfo$ = this.countryInfo
    .asObservable()
    .pipe(filter((country) => !!country));

  /**
   * Private subject
   * Array of all the countries that the letter will be written to
   * @private
   * @memberof WriteLetterService
   */
  private countryLimitations = new BehaviorSubject<Country[]>([]);

  /**
   * get the country limitations outside of the service
   *
   *  Why no filter in this BehaviorSubject?
   *  because false value is still a valid value in this case
   * @memberof WriteLetterService
   */
  public readonly countryLimitations$ = this.countryLimitations.asObservable();

  private testimonialSubject = new BehaviorSubject<{
    supporter: Testimonial;
    beneficiary: Testimonial;
  }>(undefined);

  // #endregion properties

  /**
   * Creates an instance of WriteLetterService.
   * @param {ProxyService} proxyService
   * @param {LanguageService} languageService
   * @memberof WriteLetterService
   */
  constructor(
    private proxyService: ProxyService,
    private languageService: LanguageService,
    private wordpressService: WordpressService,
    private cacheService: CacheService
  ) {
    if (this.cacheService.exists('composeLetter', 'session')) {
      const cacheData = this.cacheService.get<ProgressSubject>(
        'composeLetter',
        'session'
      );
      this.updateStorage(cacheData, false);
    } else {
      this.initProgress();
    }

    if (this.cacheService.exists('countryInfo', 'session')) {
      this.countryInfo.next(this.cacheService.get('countryInfo', 'session'));
    } else if (this.cacheService.exists('id_token', 'local')) {
      this.fetchCountryInfo();
    }
  }

  // #region Progress functions
  // FIXME: This is a bit of a mess, should be cleaned up
  private updateStorage(subject: ProgressSubject, sendCall = true) {
    this.composeLetter$.next(subject);
    this.cacheService.set('composeLetter', subject, 'session');

    if (sendCall && environment.features.useDrafts) {
      const tempSub = this.proxyService
        .post<{ groupId: string }>('auth/correspondence/draft', {
          draft: subject.data,
          options: subject.progressOptions,
        })
        .subscribe((result) => {
          this.setGroupId(result.groupId);
          // Finally unsubscribe for memory management
          tempSub.unsubscribe();
        });
    }

    this.allCountryLimitations();
  }

  /**
   * Updates the cache locally for the subject and the session cache
   * When sendCall is true (defaulted) also update it in the draft section for the API
   *
   * @private
   * @param {LetterProgress} draft
   * @param {boolean} [sendCall=true]
   * @memberof WriteLetterService
   */
  private updateCache(draft: LetterProgress, sendCall = true) {
    const { value: currentValue } = this.composeLetter$;

    const newValue: ProgressSubject = {
      data: draft,
      progressOptions: { ...currentValue.progressOptions },
    };

    this.updateStorage(newValue, sendCall);
  }

  /**
   * add options to the writeLetterSubject in the Localstorage
   *  */
  private updateOptions(options: ProgressOptions, sendCall = true) {
    const { value: currentValue } = this.composeLetter$;

    const newValue: ProgressSubject = {
      data: { ...currentValue.data },
      progressOptions: { ...options },
    };

    this.updateStorage(newValue, sendCall);
  }

  /**
   * add Options to the ProgressSubject
   * @param optionObject
   */
  addOptions(optionObject: ProgressOptions) {
    this.updateOptions(optionObject, false);
  }

  /**
   * Initialize clean progress for storage and behaviour subject
   *
   * @private
   * @memberof WriteLetterService
   */
  private initProgress(type: 'card' | 'letter' = 'card') {
    const nullState = { ...WriteLetterService.nullState };
    nullState.data.type = type;
    // console.log('nullState', nullState);
    this.updateStorage(nullState, false);
  }

  /**
   * gets all the limitations and remove the duplicate countries
   *
   * @returns
   * @memberof WriteLetterService
   */
  private allCountryLimitations() {
    const progress$ = this.letterProgress$.pipe(
      filter((value) => !!value.data.recipients),
      take(1)
    );
    const countries$ = this.getCountryInfo$.pipe(take(1));

    combineLatest([progress$, countries$]).subscribe(
      ([rawProgress, countries]: [ProgressSubject, Country[]]) => {
        const progress = { ...rawProgress.data };
        const countryInfo = [...countries];
        const bannedCountries: Country[] = [];
        progress.recipients.forEach((recipient) => {
          countryInfo.forEach((country) => {
            if (
              recipient.commitment.BeneficiaryResponse &&
              recipient.commitment.BeneficiaryResponse.Country.toLowerCase() ===
                country.slug.toLowerCase()
            ) {
              bannedCountries.push({ ...country });
            }
          });
        });
        // remove duplicate Countries
        this.countryLimitations.next([
          ...Array.from(
            new Map(bannedCountries.map((lim) => [lim.id, lim])).values()
          ),
        ]);
      }
    );
  }

  /**
   * Adds or updates the chosen template
   *
   * @param {LetterCardTemplate} template
   * @memberof WriteLetterService
   */
  setTemplate(template: LetterCardTemplate) {
    if (this.composeLetter$.value) {
      const value = { ...this.composeLetter$.value.data };

      if (template && template.type) {
        value.type = template.type;
      }

      value.template = {
        ...template,
      };

      // update subject en cache
      this.updateCache(value);
    }
  }

  useDraft(draft: LetterProgress, options: ProgressOptions) {
    this.updateCache(draft, false);
    this.updateOptions(options, false);
  }

  /**
   * Toggles commitment for selection
   *
   * @param {Commitment} commitment
   * @param {boolean} [forceAdd=false]
   * @memberof WriteLetterService
   */
  toggleCommitment(commitment: Commitment, forceAdd: boolean = false) {
    if (this.composeLetter$.value) {
      const value = {
        ...this.composeLetter$.value.data,
      };

      // Checken of recipient al in array zit
      const index = value.recipients.findIndex((c) => {
        const loop = { ...c.commitment }.BeneficiaryResponse
          .Beneficiary_GlobalID;
        const newCommitment = {
          ...commitment,
        }.BeneficiaryResponse.Beneficiary_GlobalID;
        return loop === newCommitment;
      });
      // if child is already in recipients remove else add
      if (index > -1) {
        if (!forceAdd) {
          value.recipients.splice(index, 1);
        }
      } else {
        const newObject: LetterProgressCommitment = {
          commitment,
          content: {
            text: '',
            isChanged: false,
          },
        };
        const content = this.getContent();
        if (content) {
          newObject.content.text = content;
          newObject.content.isChanged = true;
          this.resetSavedContent();
        }
        value.recipients.push(newObject);
      }

      // update subject en cache
      this.updateCache(value);
    }
  }

  /**
   * Allows adding of lots of commitments
   *
   * @param {Commitment[]} commitments
   * @returns
   * @memberof WriteLetterService
   */
  bulkAddCommitments(commitments: Commitment[]) {
    if (!this.composeLetter$.value) {
      return;
    }
    const value = { ...this.composeLetter$.value.data };
    const newRecipients: LetterProgressCommitment[] = [];
    commitments.forEach((commitment) => {
      const index = value.recipients.findIndex((c) => {
        const loop = { ...c.commitment }.BeneficiaryResponse
          .Beneficiary_GlobalID;
        const newCommitment = { ...commitment }.BeneficiaryResponse
          .Beneficiary_GlobalID;
        return loop === newCommitment;
      });
      if (index > -1) {
        newRecipients.push({ ...value.recipients[index] });
      } else {
        newRecipients.push({
          commitment,
          content: { text: '', isChanged: false },
        });
      }
    });
    value.recipients = newRecipients;

    this.updateCache(value);
  }

  /**
   * Removes all commitments
   *
   * @returns
   * @memberof WriteLetterService
   */
  removeAllRecipients() {
    if (!this.composeLetter$.value) {
      return;
    }
    const value = { ...this.composeLetter$.value.data };
    value.recipients = [];

    this.updateCache(value);
  }

  updateLanguage(lang: 'EN' | 'NL') {
    if (!this.composeLetter$.value) {
      return;
    }
    const value = { ...this.composeLetter$.value.data };

    const isEng = lang === 'EN';

    value.isEng = isEng;

    this.updateCache(value);
  }

  updateAttachments(
    type: 'user' | 'compassion',
    item: LetterAttachment[] | number
  ) {
    if (!this.composeLetter$.value) {
      return;
    }
    const value = { ...this.composeLetter$.value.data };

    if (!value) {
      return;
    }

    if (!value.attachments) {
      value.attachments = {};
    }

    if (type === 'user') {
      // check if userAttachments exists
      if (!Number.isNaN(+item) && !Array.isArray(item)) {
        return;
      }
      value.attachments.userAttachments = [...(item as LetterAttachment[])];
    }

    if (type === 'compassion') {
      if (!Number.isNaN(+item) && !Array.isArray(item)) {
        // Compassion wordpress attachment
        value.attachments.compassionAttachment = +item;
      } else if (Array.isArray(item)) {
        // Custom attachment
        // eslint-disable-next-line prefer-destructuring
        value.attachments.compassionAttachment = item[0];
      } else {
        // Shouldn't happen
      }
    }

    this.updateCache(value);
  }

  updateText(text: string, commitment: Commitment) {
    if (!this.composeLetter$.value) {
      return;
    }
    const value = { ...this.composeLetter$.value.data };

    if (!value) {
      return;
    }

    if (!value.recipients || value.recipients.length <= 0) {
      return;
    }

    const index = value.recipients.findIndex(
      (c) =>
        c.commitment.BeneficiaryResponse.Beneficiary_GlobalID ===
        commitment.BeneficiaryResponse.Beneficiary_GlobalID
    );

    if (index < 0) {
      return;
    }

    value.recipients[index].content.isChanged = true;
    value.recipients[index].content.text = text;

    this.updateCache(value);
  }

  /**
   * Resets the progress on the thank you page
   *
   * @memberof WriteLetterService
   */
  resetProgress() {
    this.initProgress();
  }

  /**
   * Fetches the testimonials via the proxy.service
   *
   * @returns {Observable<{
   *     beneficiary: Testimonial;
   *     supporter: Testimonial;
   *   }>}
   * @memberof WriteLetterService
   */
  getTestimonials(): Observable<{
    supporter: Testimonial;
    beneficiary: Testimonial;
  }> {
    if (!this.testimonialSubject.value) {
      this.getTestimonialsFromAPI().subscribe((result) => {
        this.testimonialSubject.next(result);
      });
    }

    return this.testimonialSubject
      .asObservable()
      .pipe(filter((testimonials) => testimonials !== undefined));
  }

  /**
   * Set the groupe id used for drafts when not present in the current state
   *
   * @private
   * @param {string} groupId
   * @returns
   * @memberof WriteLetterService
   */
  private setGroupId(groupId: string) {
    if (!this.composeLetter$ || !this.composeLetter$.value) {
      // Cannot happen, but you can never be too sure
      return;
    }

    if (
      this.composeLetter$.value.data.groupId &&
      this.composeLetter$.value.data.groupId === groupId
    ) {
      // GroupId is already set and the same as the new one
      return;
    }

    const newValue = { ...this.composeLetter$.value.data, groupId };

    this.updateCache(newValue, false);
  }

  public getDraft(): Observable<LetterProgress & { options: any }> {
    if (!environment.features.useDrafts) {
      return of(undefined);
    }

    return this.proxyService.get<any>('auth/correspondence/draft').pipe(
      map((rawRes) => {
        const res = { ...rawRes };
        if (res && res.template) {
          if (res.type === 'letter') {
            res.template = WriteLetterService.parseLetterDTO(res.template);
          }
          if (res.type === 'card') {
            res.template = WriteLetterService.parseCardsDTO(res.template);
          }
        }
        return res;
      })
    );
  }

  // #endregion

  // #region reuse text for next letter
  /**
   *
   * @param text save text for draft in sessionStorage
   */
  saveContent(text: string) {
    this.cacheService.set('draftContent', { text }, 'session');
  }

  /**
   * get text content for draft
   */
  private getContent(): string {
    const cache = this.cacheService.get<{ text: string }>(
      'draftContent',
      'session'
    );

    if (!cache || !cache.text) {
      return '';
    }

    return cache.text;
  }

  private resetSavedContent(): void {
    this.cacheService.remove('draftContent', 'session');
  }
  // #endregion reuse text for next letter

  // #region API calls
  /**
   * Fetches the templates via the proxy.service
   *
   * @returns {Observable<{
   *     letters: LetterTemplate[];
   *     cards: CardTemplate[];
   *   }>}
   * @memberof WriteLetterService
   */
  getTemplates(
    country: string[] = ['']
  ): Observable<{
    letters: LetterTemplate[];
    cards: CardTemplate[];
    restrictedOptions: boolean;
  }> {
    return this.languageService.selected.pipe(
      switchMap((selectedLanguage) => {
        const params = {
          lang: selectedLanguage.toLowerCase(),
          country: [...country],
        };
        return this.proxyService
          .get<{
            letters: LetterDTO[];
            cards: CardDTO[];
            restrictedOptions: boolean;
          }>('auth/correspondence/templates', {
            params,
          })
          .pipe(
            map(
              (rawTemplates: {
                letters: LetterDTO[];
                cards: CardDTO[];
                restrictedOptions: boolean;
              }) => {
                const { letters, cards, restrictedOptions } = rawTemplates;
                // parse DTO to new object
                return {
                  letters: letters.map(WriteLetterService.parseLetterDTO),
                  cards: cards.map(WriteLetterService.parseCardsDTO),
                  restrictedOptions,
                };
              }
            )
          );
      })
    );
  }

  /**
   * Get the wordpress attachments
   *
   * @param {string[]} [country=['']]
   * @returns {Observable<Attachment[]>}
   * @memberof WriteLetterService
   */
  getAttachments(country: string[] = ['']): Observable<Attachment[]> {
    return this.languageService.selected.pipe(
      switchMap((selectedLanguage) => {
        const params = {
          lang: selectedLanguage.toLowerCase(),
          country: [...country],
        };
        return this.proxyService
          .get<AttachmentDTO[]>('auth/correspondence/attachments', {
            params,
          })
          .pipe(
            map((rawAttachments: AttachmentDTO[]) => [
              ...rawAttachments.map((rawAttachment) =>
                WriteLetterService.parseAttachmentDTO(rawAttachment)
              ),
            ])
          );
      })
    );
  }

  /**
   * Fetches all countries from WP
   * including restrictions and limitations
   */
  fetchCountryInfo() {
    this.wordpressService.getCountryInfo().subscribe((countryInfo) => {
      this.cacheService.set('countryInfo', countryInfo, 'session');
      this.countryInfo.next(countryInfo);
    });
  }

  private getTestimonialsFromAPI(): Observable<{
    supporter: Testimonial;
    beneficiary: Testimonial;
  }> {
    return this.proxyService
      .get<{ supporter: TestimonialDTO; beneficiary: TestimonialDTO }>(
        'wp/testimonials'
      )
      .pipe(
        map(
          ({
            supporter,
            beneficiary,
          }: {
            supporter: TestimonialDTO;
            beneficiary: TestimonialDTO;
          }) => ({
            supporter: WriteLetterService.parseTestimonialDTO(supporter),
            beneficiary: WriteLetterService.parseTestimonialDTO(beneficiary),
          })
        )
      );
  }

  /**
   * Upload files to the backend
   *
   * @param {File[]} files
   * @returns {Observable<LetterAttachment[]>}
   * @memberof WriteLetterService
   */
  uploadFiles(files: File[]): Observable<LetterAttachment[]> {
    const formdata = new FormData();
    files.forEach((file) => {
      formdata.append('files', file);
    });

    return this.proxyService.post<LetterAttachment[]>(
      'auth/correspondence/fileupload',
      formdata
    );
  }

  /**
   * Remove files from the backend
   *
   * @param {string} fileName
   * @returns {Observable<void>}
   * @memberof WriteLetterService
   */
  removeFile(fileName: string): Observable<void> {
    return this.proxyService.del<void>(
      `auth/correspondence/fileupload/${fileName}`
    );
  }

  /**
   * get the png previews from the backend
   *
   * @param {LetterProgress} rawProgress
   * @returns {Observable<LetterPreviewResult[]>}
   * @memberof WriteLetterService
   */
  getPreviews(rawProgress: LetterProgress): Observable<LetterPreviewResult[]> {
    const progress = { ...rawProgress };

    if (
      !progress ||
      !progress.type ||
      !progress.template ||
      !progress.recipients ||
      progress.recipients.length < 1
    ) {
      return;
    }

    if (progress.type === 'card') {
      return this.getCardPreview(progress);
    }

    return this.getLetterPreview(progress);
  }

  /**
   * get the png previews from the backend for a card
   *
   * @private
   * @param {LetterProgress} rawProgress
   * @returns {Observable<LetterPreviewResult[]>}
   * @memberof WriteLetterService
   */
  private getCardPreview(
    rawProgress: LetterProgress
  ): Observable<LetterPreviewResult[]> {
    const progress = { ...rawProgress };
    const recipients = [...progress.recipients];
    const calls = [];
    if (recipients.length <= 0) {
      return;
    }
    let image: string;

    if (
      (progress.template as CardTemplate).photocard &&
      progress.attachments.compassionAttachment &&
      typeof progress.attachments.compassionAttachment !== 'number'
    ) {
      image = progress.attachments.compassionAttachment.filename;
    }

    recipients.forEach((recipient) => {
      const data: {
        template: number;
        text: string;
        image?: string;
        beneficiary: Commitment;
      } = {
        template: progress.template.id,
        text: recipient.content.text,
        beneficiary: recipient.commitment,
      };

      if (image) {
        data.image = image;
      }

      calls.push(
        this.proxyService
          .post<LetterPreview[]>('auth/correspondence/write/card/preview', data)
          .pipe(
            map((result: LetterPreview[]) => ({
              commitment: { ...recipient.commitment },
              previews: [...result],
            }))
          )
      );
    });
    return combineLatest<LetterPreviewResult[]>([...calls]);
  }

  /**
   * get the png previews from the backend for a letter
   *
   * @private
   * @param {LetterProgress} rawProgress
   * @returns {Observable<LetterPreviewResult[]>}
   * @memberof WriteLetterService
   */
  private getLetterPreview(
    rawProgress: LetterProgress
  ): Observable<LetterPreviewResult[]> {
    const progress = { ...rawProgress };
    const [recipient] = progress.recipients;
    const data: {
      template: number;
      text: string;
      images?: string[];
      attachment?: string | number;
      beneficiary: Commitment;
    } = {
      template: progress.template.id,
      text: recipient.content.text,
      beneficiary: recipient.commitment,
    };

    if (
      progress.attachments &&
      progress.attachments.userAttachments &&
      progress.attachments.userAttachments.length > 0
    ) {
      data.images = [];

      progress.attachments.userAttachments.forEach((attachment) => {
        data.images.push(attachment.filename);
      });
    }
    if (progress.attachments.compassionAttachment) {
      if (typeof progress.attachments.compassionAttachment === 'number') {
        data.attachment = `${progress.attachments.compassionAttachment}`;
      } else {
        data.attachment = progress.attachments.compassionAttachment.filename;
      }
    }

    return this.proxyService
      .post<LetterPreview[]>('auth/correspondence/write/letter/preview', data)
      .pipe(
        map((result: LetterPreview[]) => [
          {
            commitment: { ...recipient.commitment },
            previews: [...result],
          },
        ])
      );
  }

  /**
   * Send the progress to azure
   *
   * @param {LetterProgress} rawProgress
   * @returns {Observable<void[]>}
   * @memberof WriteLetterService
   */
  sendItem(rawProgress: LetterProgress): Observable<void[]> {
    const progress = { ...rawProgress };
    if (progress.type === 'letter') {
      return this.sendLetter(progress);
    }

    return this.sendCard(progress);
  }

  /**
   * Add a write date for the campaign to the user data
   *
   * @param {WPCampaign} campaign
   * @return {*}  {Observable<string>}
   * @memberof WriteLetterService
   */
  writeCampaign(campaign: WPCampaign): Observable<any> {
    return this.proxyService
      .post(
        'auth/correspondence/written-campaign',
        { campaign: campaign.id },
        {
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        catchError((error) => {
          if (error.status === 412) {
            return of('');
          }
          return throwError(error);
        })
      );
  }

  /**
   * Returns campaign ids that have been written to in the past year
   *
   * @return {*}  {Observable<string[]>}
   * @memberof WriteLetterService
   */
  getWrittenCampaigns(): Observable<string[]> {
    return this.proxyService.get('auth/correspondence/written-campaign');
  }

  /**
   * Send the letter to its recipient
   *
   * @private
   * @param {LetterProgress} rawProgress
   * @returns {Observable<void[]>}
   * @memberof WriteLetterService
   */
  private sendLetter(rawProgress: LetterProgress): Observable<void[]> {
    const progress = { ...rawProgress };
    const [recipient] = progress.recipients;
    const data: {
      template: number;
      text: string;
      isEng: boolean;
      beneficiary: Commitment;
      images?: string[];
      attachment?: number | string;
    } = {
      template: progress.template.id,
      text: recipient.content.text,
      isEng: progress.isEng,
      beneficiary: recipient.commitment,
    };

    if (
      progress.attachments &&
      progress.attachments.userAttachments &&
      progress.attachments.userAttachments.length > 0
    ) {
      data.images = [];
      progress.attachments.userAttachments.forEach((attachment) => {
        data.images.push(attachment.filename);
      });
    }

    if (progress.attachments && progress.attachments.compassionAttachment) {
      data.attachment =
        typeof progress.attachments.compassionAttachment !== 'number'
          ? progress.attachments.compassionAttachment.filename
          : progress.attachments.compassionAttachment;
    }
    return this.proxyService
      .post<void>('auth/correspondence/write/letter/send', data)
      .pipe(map((result) => [result]));
  }

  /**
   * Send the card to its recipients
   *
   * @private
   * @param {LetterProgress} rawProgress
   * @returns {Observable<void[]>}
   * @memberof WriteLetterService
   */
  private sendCard(rawProgress: LetterProgress): Observable<void[]> {
    const progress = { ...rawProgress };
    const calls: Observable<void>[] = [];
    progress.recipients.forEach((rawRecipient) => {
      const recipient = { ...rawRecipient };

      const data: {
        template: number;
        text: string;
        isEng: boolean;
        beneficiary: Commitment;
        image?: string;
      } = {
        template: progress.template.id,
        text: recipient.content.text,
        isEng: progress.isEng,
        beneficiary: recipient.commitment,
      };

      if (
        (progress.template as CardTemplate).photocard &&
        progress.attachments.compassionAttachment &&
        typeof progress.attachments.compassionAttachment !== 'number'
      ) {
        data.image = progress.attachments.compassionAttachment.filename;
      }

      calls.push(
        this.proxyService.post<void>(
          'auth/correspondence/write/card/send',
          data
        )
      );
    });

    return combineLatest([...calls]);
  }
  // #endregion

  // #region Parse functions
  /**
   * Parses the received WP data (from Proxy) into usable format
   *
   * @private
   * @static
   * @param {LetterDTO} rawLetter
   * @returns {LetterCardTemplate}
   * @memberof WriteLetterService
   */
  private static parseLetterDTO(rawLetter: LetterDTO): LetterTemplate {
    const dto = { ...rawLetter };

    return {
      id: dto.id,
      title: dto.title.rendered,
      url: dto.acf.original_text.background_image.url,
      description: dto.acf.description,
      defaultText: dto.acf.default_text,
      type: dto.type,
      slug: dto.slug,
      categories: dto.categories,
      banned_countries: dto.acf.banned_countries,
      sbc_type: dto.acf.sbc_type,
      maxLength: dto.acf.length,
      pages: {
        original_text: dto.acf.original_text,
        translation: dto.acf.translation,
        attachment: dto.acf.attachment,
      },
    };
  }

  /**
   * Parses the reveived WP data (from Proxy) into usable formar
   *
   * @private
   * @static
   * @param {CardDTO} rawCard
   * @returns {LetterCardTemplate}
   * @memberof WriteLetterService
   */
  private static parseCardsDTO(rawCard: CardDTO): CardTemplate {
    const dto = { ...rawCard };
    return {
      id: dto.id,
      title: dto.title.rendered,
      url: dto.acf.front_cover.background_image.url,
      description: dto.acf.description,
      defaultText: dto.acf.default_text,
      type: dto.type,
      slug: dto.slug,
      categories: dto.categories,
      banned_countries: dto.acf.banned_countries,
      sbc_type: dto.acf.sbc_type,
      maxLength: dto.acf.length,
      photocard: dto.acf.photocard,
      singleCommitment: !!dto.acf.singleCommitment,
      pages: {
        frontCover: dto.acf.front_cover,
        firstInner: dto.acf.first_inner,
        secondInner: dto.acf.second_inner,
        backCover: dto.acf.back_cover,
      },
    };
  }

  private static parseAttachmentDTO(rawAttachment: AttachmentDTO): Attachment {
    const dto = { ...rawAttachment };

    return {
      id: dto.id,
      url: dto.acf.image.url,
      description: dto.acf.description,
      type: dto.type,
      slug: dto.slug,
      banned_countries: dto.acf.not_allowed_countries,
      title: dto.title.rendered,
    };
  }

  private static parseTestimonialDTO(
    rawTestimonial: TestimonialDTO
  ): Testimonial {
    const dto = { ...rawTestimonial };

    return {
      id: dto.id,
      title: dto.title.rendered,
      summary: dto.acf.summary,
      full_text: dto.acf.full_text,
      author: dto.acf.author,
      origin: dto.acf.origin,
      imageUrl: dto.acf.image.url,
      type: dto.acf.type,
    };
  }
  // #endregion
}
