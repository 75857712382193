import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProxyService {
  /**
   * Will contain url based on environment
   */
  private proxyApiUrl: string;

  /**
   * Creates an instance of ProxyService.
   * @param {HttpClient} http
   * @memberof ProxyService
   */
  constructor(private http: HttpClient) {
    this.proxyApiUrl = environment.apiUrl;
  }

  /**
   * Generic get call to the PROXY
   *
   * @template T
   * @param {string} endpiont
   * @param {{ params?: string; headers?: string }} [options]
   * @returns {Observable<T>}
   * @memberof ProxyService
   */
  get<T>(
    endpoint: string,
    rawOptions?: { params?: any; headers?: any; responseType?: 'json' }
  ): Observable<T> {
    const url = `${this.proxyApiUrl}${endpoint}`;
    const options = { ...rawOptions };
    const headers = options.headers || {};
    const params = options.params || {};
    const responseType = options.responseType || 'json';
    const withCredentials = true;

    return this.http.get<T>(url, {
      params,
      headers,
      responseType,
      withCredentials,
    });
  }

  /**
   * Send a post call to the proxy
   *
   * @template T
   * @param {string} endpoint
   * @param {*} data
   * @param {{ params?: any; headers?: any }} [rawOptions]
   * @returns {Observable<T>}
   * @memberof ProxyService
   */
  post<T>(
    endpoint: string,
    data: any,
    rawOptions?: { params?: any; headers?: any; responseType?: 'json' }
  ): Observable<T> {
    const url = `${this.proxyApiUrl}${endpoint}`;
    const options = { ...rawOptions };
    const headers = options.headers || {};
    const params = options.params || {};
    const responseType = options.responseType || 'json';
    const withCredentials = true;

    return this.http.post<T>(url, data, {
      params,
      headers,
      responseType,
      withCredentials,
    });
  }

  /**
   * Send a put call to the proxy
   *
   * @template T
   * @param {string} endpoint
   * @param {*} data
   * @param {{ params?: any; headers?: any; responseType?: 'json' }} [rawOptions]
   * @returns {Observable<T>}
   * @memberof ProxyService
   */
  put<T>(
    endpoint: string,
    data: any,
    rawOptions?: { params?: any; headers?: any; responseType?: 'json' }
  ): Observable<T> {
    const url = `${this.proxyApiUrl}${endpoint}`;
    const options = { ...rawOptions };
    const headers = options.headers || {};
    const params = options.params || {};
    const responseType = options.responseType || 'json';
    const withCredentials = true;

    return this.http.put<T>(url, data, {
      params,
      headers,
      responseType,
      withCredentials,
    });
  }

  /**
   * Send a delete call to the proxy
   *
   * @template T
   * @param {string} endpoint
   * @param {{ params?: any; headers?: any }} [rawOptions]
   * @returns {Observable<T>}
   * @memberof ProxyService
   */
  del<T>(
    endpoint: string,
    rawOptions?: { params?: any; headers?: any }
  ): Observable<T> {
    const url = `${this.proxyApiUrl}${endpoint}`;
    const options = { ...rawOptions };
    const headers = options.headers || {};
    const params = options.params || {};
    const withCredentials = true;

    return this.http.delete<T>(url, { params, headers, withCredentials });
  }
}
