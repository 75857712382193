import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  of,
  throwError,
} from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Contact } from '../../interfaces/contact.model';
import { PeriodicalDonationAgreementDTO } from '../../interfaces/payment.interface';
import { TaxReportOptions } from '../../interfaces/tax-report.interface';
import { UserSetting } from '../../interfaces/user-setting.interface';
import { Account } from '../../model/account.model';
import {
  PaymentDetails,
  PaymentProfile,
} from '../../model/paymentprofile.model';
import { AuthService } from '../auth/auth.service';
import { LanguageService } from '../language/language.service';
import { ProxyService } from '../proxy.service';
import { CacheService } from '../cache.service';

/**
 * The UserService handles the registration, deletion, fetching and manipulation of users inside the app.
 */
@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private isLoggedIn = false;

  private callStatusses = {
    contact: false,
    account: false,
    paymentProfile: false,
  };

  private isBetaUser = new ReplaySubject<boolean>(1);

  public isBetaUser$ = this.isBetaUser.asObservable();

  /**
   * Array with all the descriptions to unsub from whe the page leaves
   *
   * @private
   * @type {Subscription[]}
   * @memberof UserService
   */
  private subscriptions: Subscription[] = [];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private languageService: LanguageService,
    private proxyService: ProxyService,
    private cache: CacheService
  ) {
    const loggedin$ = this.authService.getLoggedIn$().subscribe((res) => {
      this.isLoggedIn = res;
      if (res) {
        this.update();
        this.checkBetaStatus();
      } else {
        this.contact.next(undefined);
        this.address.next(undefined);
        this.paymentprofile.next(undefined);
        this.isBetaUser.next(false);
      }
    });

    this.subscriptions.push(loggedin$);
  }

  private contact = new BehaviorSubject<Contact>(null);
  private address = new BehaviorSubject<Account>(null);
  private paymentprofile = new BehaviorSubject<PaymentProfile>(null);
  private userSettings = new BehaviorSubject<Array<UserSetting>>([]);

  private update() {
    const address$ = this.getAddressFromAPI()
      .pipe(filter((address) => address !== undefined))
      .subscribe((address) => this.address.next(address));

    const contact$ = this.getContactFromAPI()
      .pipe(filter((contact) => contact !== undefined))
      .subscribe((contact) => this.contact.next(contact));

    const payment$ = this.getPaymentProfileFromAPI()
      .pipe(filter((profile) => profile !== undefined))
      .subscribe((profile) => this.paymentprofile.next(profile));

    this.subscriptions.push(address$, contact$, payment$);
  }

  private checkBetaStatus() {
    this.proxyService.get<{ status: boolean }>('auth/beta').subscribe(
      (res) => this.isBetaUser.next(res.status),
      () => this.isBetaUser.next(false)
    );
  }

  /**
   * gets account info
   *
   * @returns {Observable<Account>}
   * @memberof UserService
   */
  public getAddress$(): Observable<Account> {
    if (this.isLoggedIn && !this.address.value) {
      this.update();
    }

    return this.address.pipe(filter((address) => address !== undefined));
  }

  /**
   * get contact info
   *
   * @returns {Observable<Contact>}
   * @memberof UserService
   */
  public getContact$(): Observable<Contact> {
    if (this.isLoggedIn && !this.contact.value) {
      this.update();
    }

    return this.contact.pipe(filter((contact) => contact !== undefined));
  }

  /**
   * Get payment info
   *
   * @returns {Observable<PaymentProfile>}
   * @memberof UserService
   */
  public getPaymentProfile$(): Observable<PaymentProfile> {
    if (this.isLoggedIn && !this.paymentprofile.value) {
      this.update();
    }

    return this.paymentprofile.pipe(filter((payment) => payment !== undefined));
  }

  /**
   * Get contact info from api
   *
   * @private
   * @returns {Observable<Contact>}
   * @memberof UserService
   */
  private getContactFromAPI(): Observable<Contact> {
    if (this.callStatusses.contact) {
      return of(undefined);
    }
    this.callStatusses.contact = true;
    return this.http
      .get<any>(`${environment.apiUrl}auth/contact`, { withCredentials: true })
      .pipe(
        map((c) => {
          this.callStatusses.contact = false;
          c.Birthdate = c.Birthdate ? new Date(Date.parse(c.Birthdate)) : null;
          return c;
        }),
        catchError((err) => throwError(err))
      );
  }

  private getAddressFromAPI(): Observable<Account> {
    if (this.callStatusses.account) {
      return of(undefined);
    }
    this.callStatusses.account = true;
    return this.http
      .get<Account>(`${environment.apiUrl}auth/account`, {
        withCredentials: true,
      })
      .pipe(
        tap(() => (this.callStatusses.account = false)),
        catchError((err) => throwError(err))
      );
  }

  private getPaymentProfileFromAPI(): Observable<PaymentProfile> {
    if (this.callStatusses.paymentProfile) {
      return of(undefined);
    }
    this.callStatusses.paymentProfile = true;
    return this.http
      .get<PaymentProfile>(
        `${environment.apiUrl}auth/paymentProfile?new=true`,
        { withCredentials: true }
      )
      .pipe(
        tap(() => (this.callStatusses.paymentProfile = false)),
        catchError((err) => throwError(err))
      );
  }
  // #endregion

  updateContact(
    newContact: Partial<{
      Birthdate: string;
      MobilePhone: string;
      Phone: string;
      Email: string;
      newPassword: string;
      currentPassword: string;
    }>
  ) {
    const data: any = { ...newContact };
    return this.http
      .post<any>(`${environment.apiUrl}auth/contact`, data, {
        withCredentials: true,
      })
      .pipe(
        tap((c) => {
          const { contact } = c;
          contact.Birthdate = contact.Birthdate
            ? new Date(Date.parse(contact.Birthdate))
            : null;
          this.contact.next(contact);
        })
      );
  }

  updateAddress(newAddress: Account) {
    return this.http
      .post<void>(`${environment.apiUrl}auth/account`, newAddress, {
        withCredentials: true,
      })
      .pipe(tap(() => this.address.next(newAddress)));
  }

  updatePaymentProfile(newProfile: Partial<PaymentDetails>) {
    return this.http
      .post<void>(`${environment.apiUrl}auth/paymentProfile`, newProfile, {
        withCredentials: true,
      })
      .pipe(
        mergeMap((data) => this.getPaymentProfileFromAPI()),
        catchError((err) => of(err))
      );
  }
  // #endregion

  /**
   * Register user
   *
   * @param {string} email
   * @returns
   * @memberof UserService
   */
  register(email: string) {
    return this.http.post<any>(
      `${environment.apiUrl}onboardingEmail`,
      {
        email,
        lang: this.languageService.selected.value,
      },
      { withCredentials: true }
    );
  }

  /**
   * modify password of user
   *
   * @param {*} password
   * @returns
   * @memberof UserService
   */
  modifyPassword(password) {
    return this.http.post<any>(
      `${environment.apiUrl}auth/modifyPassword`,
      {
        password,
      },
      { withCredentials: true }
    );
  }

  requestEmailChange(newEmail: string): Observable<void> {
    return this.proxyService.put<void>(
      'auth/email',
      { newEmail },
      {
        responseType: 'text' as 'json',
      }
    );
  }

  confirmMailChange(
    hash: string
  ): Observable<{ oldMail: string; newMail: string }> {
    return this.proxyService.post<{ oldMail: string; newMail: string }>(
      'no-auth/email',
      { hash }
    );
  }

  setPassword(newPassword, hash) {
    return this.http.post<any>(
      `${environment.apiUrl}setPassword`,
      {
        password: newPassword,
        data: hash,
      },
      { withCredentials: true }
    );
  }

  /**
   * Check if the hash is still valid or not
   */
  validateHash(hash: string) {
    return this.http.get(`${environment.apiUrl}hashCheck/${hash}`, {
      withCredentials: true,
    });
  }

  autoOnboarding(hash: string): Observable<{ email: string; crypto: string }> {
    return this.http.post<{ email: string; crypto: string }>(
      `${environment.apiUrl}auto-onboarding`,
      { hash },
      { withCredentials: true }
    );
  }

  forgetPassword(username) {
    return this.http.post<any>(
      `${environment.apiUrl}forgetPassword`,
      {
        username,
        lang: this.languageService.selected.value,
      },
      { withCredentials: true }
    );
  }

  /**
   * This function makes an API call to Pro6PP. A zipcode and housenumber are passed as params.
   * When a succesful result come back, the street and city are filled in the account object.
   */
  checkZipCode(
    zipCode: string,
    houseNumber: string
  ): Observable<{ city: string; street: string; country: string }> {
    const body = {
      country: 'nl',
      zipcode: zipCode.toUpperCase(),
      number: houseNumber,
    };
    if (zipCode !== '' && houseNumber !== '') {
      return this.http
        .post<any>('https://postcode.gopublic.nl/', body, {
          headers: { 'x-api-key': environment.apikey },
          withCredentials: true,
        })
        .pipe(
          map((res) => {
            if (res) {
              return {
                city: res.settlement,
                street: res.street,
                country: res.country,
              };
            }
            return { city: '', street: '', country: '' };
          })
        );
    }
  }

  shouldAskConsent(): Observable<{ askConsent: boolean }> {
    return this.proxyService.get<{ askConsent: boolean }>('auth/consent');
  }

  updateConsent(consent: boolean): Observable<void> {
    return this.proxyService.post<void>(
      'auth/consent',
      { consent },
      {
        responseType: 'text' as 'json',
      }
    );
  }

  /**
   * Get the user settings
   *
   * @returns {Observable<Array<UserSetting>>}
   * @memberof UserService
   */
  getUserSettings(): Observable<Array<UserSetting>> {
    if (this.isLoggedIn && this.userSettings.value.length === 0) {
      this.proxyService
        .get<Array<UserSetting>>('auth/user-settings')
        .subscribe((userSettings) => {
          this.userSettings.next(userSettings);
        });
    }

    return this.userSettings
      .asObservable()
      .pipe(filter((settings) => settings.length > 0));
  }

  /**
   * Update the user settings
   *
   * @param {Array<UserSetting>} userSettings
   * @returns {Observable<Array<UserSetting>>}
   * @memberof UserService
   */
  updateUserSettings(
    userSettings: Array<UserSetting>
  ): Observable<Array<UserSetting>> {
    const body: { [key: string]: boolean } = {};

    userSettings.forEach((setting) => {
      body[setting.backendName] = setting.isSelected;
    });

    return this.proxyService
      .post<Array<UserSetting>>('auth/user-settings', body)
      .pipe(
        catchError((err) => {
          if (err.status === 304) {
            // No settings changed
            return of(this.userSettings.value);
          }

          return throwError(err);
        }),
        tap((result) => {
          this.userSettings.next(result);
        })
      );
  }

  /**
   * Check the zipcode for Belgium
   *
   * @param {string} zipCode
   * @param {string} streetName
   * @param {string} streetNumber
   * @returns {Observable<{ city: string; street: string; country: string }>}
   * @memberof UserService
   */
  checkZipCodeBe(
    zipCode: string,
    streetName: string,
    streetNumber: string
  ): Observable<{ city: string; street: string; country: string }> {
    const body = {
      country: 'be',
      zipcode: zipCode.toUpperCase(),
      street: streetName,
      number: streetNumber,
    };
    if (zipCode !== '' && streetName !== '') {
      return this.http
        .post<any>('https://postcode.gopublic.nl/', body, {
          headers: { 'x-api-key': environment.apikey },
          withCredentials: true,
        })
        .pipe(
          map((res) => {
            if (res.length > 0) {
              return {
                city: res.region,
                street: res.street,
                country: res.country,
              };
            }
            return { city: '', street: '', country: '' };
          })
        );
    }
  }

  /**
   * Check the zipcode for Germany
   *
   * @param {string} zipCode
   * @param {string} streetName
   * @param {string} settlementName
   * @param {string} streetNum
   * @returns {Observable<{ city: string; street: string; country: string }>}
   * @memberof UserService
   */
  checkZipCodeDe(
    zipCode: string,
    streetName: string,
    settlementName: string,
    streetNum: string
  ): Observable<{ city: string; street: string; country: string }> {
    const body = {
      country: 'de',
      zipcode: zipCode.toUpperCase(),
      street: streetName,
      city: settlementName,
      number: streetNum,
    };
    if (zipCode !== '' && settlementName !== '') {
      return this.http
        .post<any>('https://postcode.gopublic.nl/', body, {
          headers: { 'x-api-key': environment.apikey },
          withCredentials: true,
        })
        .pipe(
          map((res) => {
            if (res.length > 0) {
              return {
                city: res.settlement,
                street: res.street,
                country: res.country,
              };
            }
            return { city: '', street: '', country: '' };
          })
        );
    }
  }

  /**
   * get the options of the taxreport
   * */
  getTaxreportOptions(): Observable<TaxReportOptions> {
    return this.proxyService
      .get<TaxReportOptions>(`/auth/payments/tax-report/options`)
      .pipe(
        map((result) => {
          if (!result || !result.availability) {
            return result;
          }
          const newAvailability = [
            ...result.availability
              .map((item) => {
                if (Number.isNaN(+item)) {
                  return undefined;
                }
                return +item;
              })
              .filter((item) => !!item)
              .sort((a, b) => b - a),
          ];

          return { ...result, availability: newAvailability };
        })
      );
  }

  /**
   * Get a taxreport by year and language so it can be downloaded
   *
   */
  getTaxReport(year: number, variant: string): Observable<Blob> {
    return this.proxyService.get<any>(`auth/payments/tax-report`, {
      responseType: 'blob' as 'json',
      params: { year, variant },
    });
  }

  /**
   * get Periodical agreement info
   * @param year
   */
  getPeriodicalDonationAgreement(
    year: number = new Date().getFullYear()
  ): Observable<PeriodicalDonationAgreementDTO[]> {
    return this.proxyService.get<any>(
      `auth/payments/periodical-donation-agreement`,
      {
        params: { year },
      }
    );
  }

  /**
   * Unsubscribe from the observables
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public removeForcePasswordKey(): void {
    if (this.cache.exists('forcePasswordChange', 'local'))
      this.cache.remove('forcePasswordChange', 'local');
  }
}
